
<template>
  <div>
    <CCard accentColor="primary">
      <CCardBody>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
          <CForm @submit.stop.prevent="handleSubmit(submit)">
            <CRow>
              <CCol md="6">
                <validation-provider
                  rules="required"
                  v-slot="{ errors }"
                  name="Username"
                >
                  <CInput
                    label="User Name"
                    placeholder="User Name"
                    v-model="form.username"
                    :invalid-feedback="errors[0]"
                    :isValid="!errors[0]"
                  ></CInput>
                </validation-provider>
              </CCol>
              <CCol md="6">
                <validation-provider
                  rules="required"
                  v-slot="{ errors }"
                  name="Nama Lengkap"
                >
                  <CInput
                    label="Nama Lengkap"
                    placeholder="Nama Lengkap"
                    v-model="form.fullname"
                    :invalid-feedback="errors[0]"
                    :isValid="!errors[0]"
                  ></CInput>
                </validation-provider>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6">
                <validation-provider
                  rules="required|email"
                  v-slot="{ errors }"
                  name="Email"
                >
                  <CInput
                    type="email"
                    label="Email"
                    placeholder="Email"
                    v-model="form.email"
                    :invalid-feedback="errors[0]"
                    :isValid="!errors[0]"
                  ></CInput>
                </validation-provider>
              </CCol>
              <CCol md="6">
                <CInput
                  label="NIP"
                  placeholder="NIP"
                  v-model="form.nip"
                  :isValid="true"
                ></CInput>
              </CCol>
            </CRow>
            <!-- <CRow>
              <CCol md="6">
                <validation-provider
                  rules="required"
                  v-slot="{ errors }"
                  name="Role"
                >
                  <CInput
                    label="Role"
                    placeholder="Role"
                    v-model="form.role_id"
                    :invalid-feedback="errors[0]"
                    :isValid="!errors[0]"
                  ></CInput>
                </validation-provider>
              </CCol>
            </CRow> -->
            <CRow>
              <CCol md="6">
                <validation-provider v-slot="{ errors }" name="Password">
                  <CInput
                    type="password"
                    label="Password"
                    placeholder="Password"
                    v-model="form.password"
                    :invalid-feedback="errors[0]"
                    :isValid="!errors[0]"
                  ></CInput>
                </validation-provider>
              </CCol>
              <CCol md="6">
                <validation-provider
                  vid="confirm"
                  rules="confirmed:Password"
                  v-slot="{ errors }"
                  name="Konfirmasi Password"
                >
                  <CInput
                    type="password"
                    label="Konfirmasi Password"
                    placeholder="Konfirmasi Password"
                    v-model="form.password_confirmation"
                    :invalid-feedback="errors[0]"
                    :isValid="!errors[0]"
                  ></CInput>
                </validation-provider>
              </CCol>
            </CRow>
            <CRow>
              <CCol col="6" class="text-left">
                <CSpinner color="success" size="sm" v-if="loading" />
                <CButton color="primary" class="px-4" type="submit" v-else
                  >Simpan</CButton
                >
              </CCol>
            </CRow>
          </CForm>
        </ValidationObserver>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
export default {
  name: "User",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      errorMessage: null,
      form: {
        id: null,
        username: null,
        fullname: null,
        email: null,
        password: null,
        password_confirmation: null,
        nip: null,
      },
      loading: false,
      item: null,
    };
  },
  computed: {
    ...mapGetters("auth", {
      user: "user",
    }),
  },
  mounted() {
    if (this.user) {
      this.form.id = this.user.id;
      this.form.username = this.user.username;
      this.form.fullname = this.user.fullname;
      this.form.email = this.user.email;
      this.form.nip = this.user.nip;
    }
  },
  watch: {
    user: function (user) {
      console.log(user);
    },
  },
  methods: {
    async submit() {
      const vm = this;
      try {
        this.loading = true;
        let { status } = await this.$store.dispatch("users/update", {
          data: this.form,
          id: vm.user.id,
        });
        if (status >= 200 && status <= 202) {
          Swal.fire({
            title: "Sukses",
            text: "Data berhasil tersimpan!",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Tutup!",
          }).then(() => {
            if (!vm.user) {
              requestAnimationFrame(() => {
                this.$refs.observer.reset();
              });
            }
            vm.$emit("done");
          });
        }
      } catch (x) {
        console.log(x);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
